import { graphql, HeadFC } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";
import SEO from "../components/seo";

export interface IndexPageProps {
  data: QueryType;
}

type QueryType = {
  // groupPhoto: {
  //   childImageSharp: {
  //     gatsbyImageData: IGatsbyImageData;
  //   };
  // };
  portraitSchoettes: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  portraitSoljan: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  portraitSalamon: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const IndexPage: React.FC<IndexPageProps> = ({ data }) => {
  return (
    <main className="relative flex flex-col flex-grow h-full bg-beige">
      <div className="grid w-full h-full max-w-3xl grid-cols-2 gap-8 px-6 py-12 mx-auto lg:max-w-7xl md:px-12">
        <div className="grid items-center order-1 h-full grid-cols-2 col-span-2 gap-6 sm:grid-cols-5 lg:order-2 lg:col-span-1">
          <div className="col-span-1 sm:col-span-2 aspect-3/4 rounded-2xl bg-[#dbd9cc]">
            <GatsbyImage
              image={data.portraitSchoettes.childImageSharp.gatsbyImageData}
              alt="Gruppenfoto"
              objectFit="contain"
              objectPosition="bottom"
              className="w-full h-full"
            />
          </div>
          <div className="grid grid-cols-2 gap-6 col-sapn-1 sm:col-span-3 sm:grid-cols-3">
            <div className="col-span-2 overflow-hidden  sm:col-span-3 aspect-square sm:aspect-video lg:aspect-4/3 rounded-2xl bg-[#bfbdb3]">
              <GatsbyImage
                image={data.portraitSoljan.childImageSharp.gatsbyImageData}
                alt="Gruppenfoto"
                objectFit="contain"
                className="w-full h-full"
              />
            </div>
            <div className="col-span-2 overflow-hidden  aspect-square rounded-2xl bg-[#dbd9cc]">
              <GatsbyImage
                image={data.portraitSalamon.childImageSharp.gatsbyImageData}
                alt="Gruppenfoto"
                objectFit="contain"
                className="w-full h-full"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center order-2 h-auto col-span-2 lg:h-full lg:order-1 lg:col-span-1">
          <h1 className="pt-4 font-normal !leading-[1.1] text-center lg:text-left text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-red-accent md:pt-0 hyphens-none">
            Willkommen in der Psychotherapie Praxis Düsseldorf
          </h1>
        </div>
      </div>
      {/* <GatsbyImage
        image={data.groupPhoto.childImageSharp.gatsbyImageData}
        alt="Gruppenfoto"
        objectFit="contain"
        objectPosition="50% 100%"
        imgClassName="object-contain"
        className="absolute bottom-0 order-2 w-full h-full -translate-x-1/2 md:!absolute left-1/2 object-contain"
      /> */}
      {/* <div className="bottom-0 flex-grow order-1 w-full p-4 md:bg-white md:absolute md:bg-opacity-90">
        <p className="pt-4 text-3xl text-center text-red-accent font-extralight md:pt-0">
          Willkommen in der Psychotherapie Praxis Düsseldorf
        </p>
      </div> */}
    </main>
  );
};

export const query = graphql`
  query IndexQuery {
    # groupPhoto: file(relativePath: { eq: "3er-Gruppenbild_wide_v2.png" }) {
    #   childImageSharp {
    #     gatsbyImageData(
    #       width: 1600
    #       quality: 90
    #       placeholder: BLURRED
    #       blurredOptions: { width: 80 }
    #     )
    #   }
    # }
    portraitSchoettes: file(
      relativePath: { eq: "home-portrait-schoettes.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    portraitSoljan: file(relativePath: { eq: "home-portrait-soljan.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
    portraitSalamon: file(relativePath: { eq: "home-portrait-salamon.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          quality: 90
          placeholder: BLURRED
          blurredOptions: { width: 80 }
        )
      }
    }
  }
`;

export const Head: HeadFC = () => <SEO />;

export default IndexPage;
